import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { HeroImageRight } from '../components/General/HeroImageRight';
import { HeroVideo } from '../components/General/HeroVideo';
import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import { FeatureFAQ } from '../components/ProductFeature/FeatureFAQ';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import { SoftwareFeatures } from '../components/Comparisons/SoftwareFeatures';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { CtaCard } from '../components/General/CtaCard';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.white,
  },
  heroVideo: {
    marginBottom: '5rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
    },
  },
  softwareFeaturesContainer: {
    //paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  formWaveDown: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-3rem',
    },
  },
  formWaveUp: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-10rem',
    },
  },
  formCont: {
    padding: '16rem 0',

    [theme.breakpoints.down('xs')]: {
      padding: '6rem 0 8rem 0',
    },
  },
  faqContainer: {
    background: '#f4f8ff',
    paddingBottom: '10rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '-2rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-5rem',
    },
  },
  testimonialBackground: {
    backgroundSize: 'cover',
    height: '600px',
    width: '99vw',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
  },
  testimonialContainer: {
    marginTop: '-35rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '-30rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-25rem',
    },
  },
}));

export default function Comparisons({ data: { comparisons }, location }) {
  const classes = useStyles();

  const {
    _id,
    metaTitle,
    metaDescription,
    marketoId,
    pardotUrl,
    contactForm,
    hero,
    heroImage,
    heroVideo,
    heroAlignCenter,
    formBgImage,
    resourceTitle,
    resources,
    _rawResourceBody,
    faq,
    faqTitle,
    softwareFeatures,
    supportedProductsHeader,
    testimonial,
    testimonialTitle,
    testimonialBackground,
    customerLogos,
    customerLogosHeader,
    cta,
  } = comparisons;
  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      {heroAlignCenter && heroVideo ? (
        <div className={classes.heroVideo}>
          <Hero hero={hero} feature />
          <Container>
            <HeroVideo wistiaLink={heroVideo} />
          </Container>
        </div>
      ) : heroAlignCenter ? (
        <Hero hero={hero} feature />
      ) : (
        <HeroImageRight
          _id={_id}
          hero={hero}
          heroImage={heroImage?.asset?.gatsbyImageData}
          feature
        />
      )}

      {/* <WaveUpSVG height='213' width='100%' fill='#fff' /> */}
      <WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
      <Container className={classes.softwareFeaturesContainer}>
        {cta ? (
          <CtaCard
            header={cta._rawCtaBlockHeader}
            ctaText={cta.ctaText}
            background={cta.ctaBackground}
          />
        ) : null}
        <SoftwareFeatures
          header={
            cta
              ? null
              : 'PestPac is chosen by 7 of the top 10 and 60% of the top 100 pest control companies'
          }
          // subheader={supportedProductsSubheader}
          softwareFeatures={softwareFeatures}
          // accentColor={accentColor[0].hexValue}
        />
      </Container>
      <WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
      {faq.length ? (
        <div className={classes.faqContainer}>
          <Container>
            <FeatureFAQ header={faqTitle} faq={faq} />
          </Container>
        </div>
      ) : null}

      {/* <div>
				<WaveUpSVG height='213' width='100%' fill={'#FFFFFF'} />
			</div> */}
      <div
        className={classes.testimonialBackground}
        style={{
          backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
        }}
      />
      <WaveUpSVG fill='#FFF' />
      <div className={classes.testimonialContainer}>
        <Testimonials
          header={testimonialTitle}
          testimonials={testimonial}
          // formRef={formRef}
          background
        />
      </div>
      <Container>
        <CustomerLogos
          customerLogosArray={customerLogos}
          customerLogosHeader={customerLogosHeader}
        />
      </Container>
      <WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
      <div
        className={classes.formCont}
        style={{
          backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
          backgroundSize: 'cover',
        }}
      >
        <Form
          formId={marketoId}
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          privacy
          modal={false}
          location={location}
        />
      </div>
      <WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
      <Container>
        <Resources
          header={resourceTitle}
          resources={resources}
          subheader={_rawResourceBody}
        />
      </Container>
      <div>
        <WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
      </div>
      {/* <ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/> */}
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    comparisons: sanityComparisons(slug: { current: { eq: $slug } }) {
      _id
      title
      metaTitle
      metaDescription
      slug {
        current
      }
      hero {
        _rawContent
        backgroundImage {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaButtonText
        ctaButtonLink
        internalLink
      }
      heroImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      heroVideo
      heroAlignCenter
      # softwareFeatureListHeader
      cta {
        ctaHeader
        ctaBackground {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaSubheader
        ctaText
        ctaLink
        internalLink
        _rawCtaBlockHeader
      }
      softwareFeatures {
        title
        comparisonHeader
        featureListCta
        logos {
          asset {
            gatsbyImageData
          }
        }
        alternateTextHeaders
        features {
          featureTitle
          featureDescription
          colOne
          colTwo
          comparisonText
          colThree
        }
        expandedListHeader
        expandedListSubheader
        expandText
        hideText
        softwareLogoAndDescription {
          softwareComparisonTitle
          logos {
            asset {
              gatsbyImageData(placeholder: BLURRED, height: 85)
            }
          }
        }
      }
      testimonialTitle
      testimonial {
        title
        header
        testimonialLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        company
        testimonialText
        nameAndTitle
        videoVariant
        image {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              height: 450
              width: 775
              placeholder: BLURRED
            )
          }
        }
      }
      testimonialBackground {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
      formBgImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      contactForm {
        header
        privacyPolicy
      }
      marketoId
      pardotUrl
      customerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      faqTitle
      faq {
        question
        _rawAnswer
      }
    }
  }
`;

import React, { useState, useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { ExpandedSection } from './ExpandedSection';
import { MobileFeatures } from './Mobile/MobileFeatures';
import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  introContainer: {
    textAlign: 'center',
    paddingTop: '3rem',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  featureCell: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  featureWrapper: {
    display: 'flex',
    marginLeft: '1rem',
  },
  featureDescription: {
    margin: '.5rem 0 0 1rem',
    fontSize: '1.125rem',
    fontWeight: 400,
    color: '#5A7184',
  },
  headerCell: {
    background: theme.workwaveBlue,
    borderLeft: '2px solid #C3CAD9',
    borderTop: '2px solid #C3CAD9',
    // borderRadius: '20px 0px 0px 0px',
  },
  header: {
    fontWeight: 600,
    marginLeft: '1rem',
    color: 'white',
  },
  sectionHeader: {
    fontWeight: 700,
    color: theme.workwaveBlue,
  },
  subHeader: {
    color: theme.workwaveBlue,
  },
  button: {
    cursor: 'pointer',
    opacity: 1,
    textDecoration: 'none',
    transition: 'all .5s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  expandText: {
    cursor: 'pointer',
    textAlign: 'center',
    paddingBottom: '4rem',
    fontWeight: 600,
    opacity: 1,
    transition: 'all .5s',
    paddingTop: '2rem',
    '&:hover': {
      opacity: 0.8,
    },
  },
  compareTitleCell: {
    borderTopLeftRadius: '22px',
    borderTopRightRadius: '22px',
    borderColor: '#ffffff !important',
    borderWidth: 0,
    borderStyle: 'solid',
  },
  subtitleText: {
    color: '#5A7184',
    fontSize: '0.9375rem',
    marginTop: '10px',
  },
  otherCompareLink: {
    textDecoration: 'none',
    color: theme.workwaveBlue,
    '&:hover': {
      '& $arrow': { transform: 'translateX(10px)' },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  arrow: {
    marginLeft: '.25rem',
    transition: 'transform .25s ease-in-out',
    display: 'inline-block',
  },
}));

const StyledCell = withStyles({
  root: {
    border: '2px solid #C3CAD9',
  },
})(TableCell);

const StyledBody = withStyles({
  root: {
    borderRadius: '0px 20px 20px 20px',
  },
})(TableBody);

const Feature = ({ feature, logos }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <StyledCell
      component='th'
      scope='row'
      onClick={() => setOpen(!open)}
      className={classes.featureCell}
      style={{
        width: logos.length === 1 ? '80%' : logos.length === 3 ? '60%' : '70%',
      }}
    >
      <div className={classes.featureWrapper}>
        <Typography
          variant='h6'
          color='primary'
          style={{ fontSize: '1.4rem', fontWeight: 600 }}
        >
          {feature.featureTitle}
        </Typography>
        <IconButton
          aria-label='expand row'
          size='small'
          color='secondary'
          style={{ marginLeft: '.5rem' }}
        >
          {open ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Typography variant='body1' className={classes.featureDescription}>
          {feature.featureDescription}
        </Typography>
      </Collapse>
    </StyledCell>
  );
};

export const SoftwareFeatures = ({
  softwareFeatures,
  header,
  subheader,
  modal,
  accentColor,
}) => {
  const classes = useStyles();
  const {
    comparisonHeader,
    alternateTextHeaders,
    logos,
    features,
    ctaLineTitle,
    ctaRows,
    expandText,
    hideText,
    expandedListHeader,
    expandedListSubheader,
    associatedProducts,
    softwareLogoAndDescription,
    comparisonText,
  } = softwareFeatures;

  const [expanded, setExpanded] = useState(false);
  const md = useMediaQuery('(max-width: 960px)');

  const { handleModalClick } = useContext(FormModalContext);

  const otherCompareName =
    alternateTextHeaders[1] === 'Briostack' ? 'FieldRoutes' : 'Briostack';

  return (
    <>
      {!modal && header && (
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignContent='center'
          spacing={2}
          className={classes.introContainer}
        >
          <Grid item xs={12} md={10} lg={8}>
            <Typography variant='h2' className={classes.sectionHeader}>
              {header}
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Typography body='body1' className={classes.subHeader}>
              {subheader}
            </Typography>
          </Grid>
        </Grid>
      )}

      {md ? (
        <MobileFeatures
          features={features}
          comparisonHeader={comparisonHeader}
          headers={alternateTextHeaders}
          logos={logos}
          ctaLineTitle={ctaLineTitle}
          ctaRows={ctaRows}
          associatedProducts={associatedProducts}
          modal={modal}
        />
      ) : (
        <TableContainer
          style={{
            paddingTop: '2rem',
            paddingBottom: '2rem',
            position: 'relative',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledCell
                  component='th'
                  scope='row'
                  style={{ borderTop: 0, borderLeft: 0 }}
                ></StyledCell>

                {logos.map((logo, index) => (
                  <StyledCell align='center' key={index}>
                    <GatsbyImage
                      image={logo.asset?.gatsbyImageData}
                      imgStyle={{ objectFit: 'contain' }}
                      style={{ maxWidth: index == 1 ? '130px' : '72px' }}
                    />
                  </StyledCell>
                ))}
              </TableRow>
            </TableHead>
            {/* all features are listed similarly in sanity. we take the top 10 here and the rest go to the show all expanded component below */}
            <StyledBody>
              <TableRow>
                {/* <StyledCell
									component='th'
									scope='row'
									// onClick={() => setOpen(!open)}
									className={classes.featureCell}
									style={{
										width:
											logos.length === 1
												? '80%'
												: logos.length === 3
												? '60%'
												: '70%',
										background: '#EFF2F7',
									}}></StyledCell> */}
                <TableCell align='center' className={classes.headerCell}>
                  <Typography variant='h5' className={classes.header}>
                    {comparisonHeader}
                  </Typography>
                </TableCell>
                <StyledCell
                  align='center'
                  style={{
                    background: '#f7f7f7',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      size='3x'
                      style={{
                        background: '#E5EAF4',
                        borderRadius: '8px',
                        padding: '6px 8px',
                        color: '#2a7abc',
                      }}
                    />
                    <Typography
                      variant='body1'
                      className={classes.subtitleText}
                      style={{ marginTop: 0 }}
                    >
                      &nbsp;Full&nbsp;Functionality
                    </Typography>
                  </div>
                </StyledCell>
                <StyledCell
                  align='center'
                  style={{
                    background: '#f7f7f7',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'x']}
                      size='3x'
                      style={{
                        background: '#E5EAF4',
                        borderRadius: '8px',
                        padding: '6px 8px',
                        color: '#c04444',
                      }}
                    />
                    <Typography
                      variant='body1'
                      className={classes.subtitleText}
                      style={{ marginTop: 0 }}
                    >
                      &nbsp;Partial&nbsp;Functionality
                    </Typography>
                  </div>
                </StyledCell>
              </TableRow>
              {features.slice(0, 10).map((feature, index) => (
                <TableRow key={index}>
                  <Feature feature={feature} logos={logos} />
                  <StyledCell align='center'>
                    {feature.colOne && (
                      <FontAwesomeIcon
                        icon={['fas', 'check']}
                        size='3x'
                        style={{
                          background: '#E5EAF4',
                          borderRadius: '8px',
                          padding: '6px 8px',
                          color: '#2a7abc',
                        }}
                      />
                    )}
                  </StyledCell>
                  {/* uses the length of the logos array to determine if these cells will be filled. further checks the boolean attached */}
                  {(logos.length || alternateTextHeaders.length >= 2) && (
                    <StyledCell align='center'>
                      {feature.colTwo && (
                        <>
                          <FontAwesomeIcon
                            icon={['fas', 'x']}
                            size='3x'
                            style={{
                              background: '#E5EAF4',
                              borderRadius: '8px',
                              padding: '6px 8px',
                              color: '#c04444',
                              marginTop: feature.comparisonText && '25px',
                            }}
                          />
                          {feature.comparisonText ? (
                            <Typography
                              variant='body1'
                              className={classes.subtitleText}
                              style={{ bottom: 0 }}
                            >
                              {feature.comparisonText}
                            </Typography>
                          ) : null}
                        </>
                      )}
                    </StyledCell>
                  )}

                  {logos.length === 3 && (
                    <StyledCell align='center'>
                      {feature.colThree && (
                        <FontAwesomeIcon
                          icon={['fas', 'check']}
                          size='3x'
                          style={{
                            background: '#E5EAF4',
                            borderRadius: '8px',
                            padding: '6px 8px',
                            color: '#055291',
                          }}
                        />
                      )}
                    </StyledCell>
                  )}
                </TableRow>
              ))}

              <TableRow>
                <StyledCell colSpan={1} variant='footer'>
                  <Typography
                    variant='h6'
                    color='secondary'
                    style={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      marginLeft: '1rem',
                    }}
                  >
                    Ready to learn more?
                  </Typography>
                </StyledCell>

                <StyledCell
                  colSpan={3}
                  align='center'
                  variant='footer'
                  // href={cta.ctaLink}
                  onClick={handleModalClick}
                  target='_blank'
                  aria-label='Opens in a new tab'
                  style={{
                    background: '#055291',
                  }}
                  className={classes.button}
                >
                  <Typography
                    variant='body1'
                    style={{
                      color: 'white',
                      textTransform: 'uppercase',
                      fontWeight: 600,
                    }}
                  >
                    CHAT WITH US TODAY
                  </Typography>
                </StyledCell>
              </TableRow>
            </StyledBody>
          </Table>
        </TableContainer>
      )}
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{ marginTop: '2rem' }}
      >
        <Typography variant='h4' color='primary'>
          <a
            href={`/compare/${otherCompareName.toLowerCase()}-alternative/`}
            className={classes.otherCompareLink}
          >
            See How PestPac Stacks Up Against {otherCompareName}
            <span className={classes.arrow}>→</span>
          </a>
        </Typography>
      </Grid>
      {/* <Collapse in={expanded}>
				<ExpandedSection
					header={expandedListHeader}
					subheader={expandedListSubheader}
					features={features.slice(10)}
					logos={logos}
				/>
			</Collapse>
			<Grid container alignItems='center' justifyContent='center'>
				<Button
					variant='contained'
					size='small'
					onClick={(e) => setExpanded(!expanded)}
					style={{
						background: accentColor ?? '#002D5C',
						color: 'white',
						margin: '2rem 0',
					}}>
					{expanded ? hideText : expandText}
					<IconButton
						aria-label='expand row'
						size='small'
						style={{ color: 'white' }}>
						{expanded ? <RemoveIcon /> : <AddIcon />}
					</IconButton>
				</Button> */}
      {/* </Grid> */}
    </>
  );
};
